<template>
  <div></div>
</template>

<script>
import { TweenMax, TweenLite, TimelineMax, Linear } from "gsap/all";

export default {
  name: "CardsPalette",
  props: ["img_urls"],
  mounted() {
    const vm = this;

    function palette(cont, img_urls) {
      cont.style.position = "relative";

      cont.onresize = function() {
        console.log("resize");
        initialize();
      };

      let dim = {};

      function destroy() {
        const imgs = cont.querySelectorAll("*");
        imgs.forEach(el => el.remove());
      }

      function initialize() {
        destroy();
        setupDims();
        create();
        start();
      }

      function setupDims() {
        (function setContDim() {
          const fake_img = cont.appendChild(document.createElement("img"))
          fake_img.classList = "fake"
          fake_img.style.visibility = "hidden"
          fake_img.style.width = "100%"
          fake_img.src = img_urls[0];
        })();

        dim.full_width = cont.getBoundingClientRect().width;
        dim.full_height = cont.getBoundingClientRect().height;
        dim.card = {};
        dim.card.width = dim.full_width * 0.88;
        dim.card.height = dim.full_height * 0.88

        cont.style.height = dim.card.height;
      }

      function isWidthChanged() {
        return dim.full_width !== cont.getBoundingClientRect().width
      }

      function create() {

        img_urls.forEach(img_url => {
          const img_el = cont.appendChild(document.createElement("img"));
          img_el.classList = "card"
          img_el.src = img_url;
          img_el.style.width = dim.card.width + "px";
          img_el.style.position = "absolute";
          img_el.style.top = "0";
          img_el.style.left = "0";
          img_el.style["background-color"] = "rgba(255,255,255,0)";
          img_el.style.border = "none";
        });
        setTransformOrigin()
      }
      function start() {
        let imgs = getCards(),
          imgs_num = imgs.length,
          switch_dur = 6,
          total_dur = switch_dur * imgs_num,
          x_offset = dim.full_width - dim.card.width;

        function reOrder(nodes, first) {
          const total = nodes.length - 1;

          nodes.forEach((node, index) => {
            let temp1 = index - first,
              zIndex;
            if (temp1 < 0) zIndex = total + temp1;
            else zIndex = temp1 - 1;
            node.style.zIndex = zIndex + 1;
          });
        }
        reOrder(imgs, 0);

        imgs.forEach((el, i) => {
          TweenLite.set(el, {
            scale: 0.8,
          });

          const tl = new TimelineMax({
            repeat: -1,
          });

          tl.to(el, total_dur - switch_dur, {
            x: x_offset,
            scale: 1,
            ease: Linear.easeNone,
          });

          tl.to(el, switch_dur / 2, {
            x: dim.card.width + x_offset,
            ease: Linear.easeInOut,
            onComplete: () => {
              reOrder(imgs, i);
            }
          });

          tl.to(el, switch_dur / 2, {
            scale: 0.8,
            x: 0,
            ease: Linear.easeInOut,
          });

          tl.seek(switch_dur * i);
          // tl.pause()
        });

      }

      function getCards() {
        return cont.querySelectorAll("img.card")
      }

      function setTransformOrigin() {
        let pos = ((cont.getBoundingClientRect().top + dim.full_height/2) / window.innerHeight)
        pos = 1-pos
        pos = pos - .5
        pos = pos * 4
        pos = pos + .5

        let y_transform_origin = Math.floor(pos*100)
        y_transform_origin = "70"  // :(
        getCards().forEach(el => {
          el.style["transform-origin"] =  "0% "+y_transform_origin+"%"
        })
      }

      initialize();

      return {
        cont,
        initialize,
        destroy,
        isWidthChanged,
        setTransformOrigin
      };
    }

    vm.palette = palette(vm.$el, vm.img_urls);
  },
  created() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onResize() {
      const vm = this;
      if (!vm.palette) return
      if (vm.palette.isWidthChanged()) vm.palette.initialize();
    },
    onScroll() {
      const vm = this;
      if (!vm.palette) return

      vm.palette.setTransformOrigin()
    }
  }
};
</script>

<style scoped></style>
