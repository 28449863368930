<template>
  <div>
    <div class="image_parallax" :style="{
      'background-image': 'url(\'' + bg_url + '\')',
      'background-position-y': bg_pos_y || '50%'
      }">
    </div>
    <div class="root_cont">
      <div class="container">
        <div class="first-row">
          <div class="on-left">
            <h1 class="fw-200">
              <span v-html="t.head1"/>
            </h1>
            <h5 class="fw-400 mt-3">
              <span v-html="t.underhead1"/>
            </h5>
            <div>
            <span
              @click="$router.push({name: $route.meta.registration_route_name || 'croatian_land_rights'})"
              class="btn btn-lg button_slide_white mt-2 reg"
            >
              <span v-html="t.REGISTER"/>
            </span>
            </div>
          </div>
          <div class="on-right position-relative">
            <div class="report_cont">
              <img src="/static/images/ReportTilted.png" alt="">
            </div>
          </div>
        </div>
        <div class="second-row">
          <h3 class="fw-800">
            <span v-html="t.head2"/>
          </h3>
          <h5 class="fw-400">
            <span v-html="t.underhead2"/>
          </h5>
          <div class="cards_cont">
            <CardsPalette :img_urls="[
              '/static/images/landing_page_cards/Card1.png',
              '/static/images/landing_page_cards/Card2.png',
              '/static/images/landing_page_cards/Card3.png',
              '/static/images/landing_page_cards/Card4.png',
            ]"/>
          </div>
          <div class="mt-5">
            <h3 class="text-center counter_head narrow fw-800">
              <span v-html="t.counter_head"/>
            </h3>
            <HomeCounter/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const t =
    //<i18n>
{
  "en": {
    "head1": "Could you have an Ancestral Claim to Land?",
    "underhead1": "Register and download your free, personalized report today",
    "REGISTER": "LET'S GO",
    "head2": "YOUR PERSONALIZED FREE REPORT",
    "underhead2": "A free, detailed summary of the top 10 parcels of land identified against each member of your family tree",
    "counter_head": "REGISTERED USER SEARCH RESULTS TO DATE:"
  },
  "hr": {
    "head1": " Jeste li naslijedili pravo na zemljište?",
    "underhead1": "Registrirajte se i preuzmite svoje besplatno personalizirano izvješće danas",
    "REGISTER": "KRENI",
    "head2": "VAŠE BESPLATNO IZVJEŠĆE",
    "underhead2": "Besplatan detaljan pregled prvih 10 zemljišnih čestica pronađenih za svakog člana obiteljskog stabla",
    "counter_head": "REZULTATI PRETRAGE REGISTRIRANIH KORISNIKA DO DANAS:"
  },
  "es": {
    "head1": "Aproximadamente el 10 % de todos los terrenos en Croacia no tiene un título limpio",
    "underhead1": "Regístrese y explore qué derechos sobre terrenos podría tener.",
    "REGISTER": "REGÍSTRESE",
    "head2": "SU INFORME GRATUITO",
    "underhead2": "El resumen detallado gratuito de 10 parcelas principales para cada miembro de la familia",
    "counter_head": "REGISTERED USER SEARCH RESULTS TO DATE:"
  }
}
//</i18n>

  import CardsPalette from "./CardsPalette"
  import HomeCounter from "../../components/web/home/HomeCounter"
  export default {
    name: "LandingPageHome",
    components: {HomeCounter, CardsPalette},
    props: ["bg_url", "bg_pos_y"],
    data() {
      return {
        t: t[this.$lang]
      }
    }
  }
</script>

<style scoped>
  .root_cont {
    color: #fff;
    padding: 40px 0;
    overflow: hidden;
    position: relative;
  }

  .image_parallax {
    position: fixed;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;

    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 0;
  }

  .first-row {
    position: relative;
    width: 100%;
    max-width: 700px;
    padding: 40px 0;
    margin: 0 auto 20px;

    display: flex;
  }

  .first-row .on-left {}

  .first-row .on-right {}

  .report_cont {
    position: absolute;
    left: 10px;
    top: -60px;
    width: 350px;
  }

  .report_cont img {
    width: 100%;
  }

  .first-row h1 {
    margin-top: 70px;
    font-size: 2.4rem;
    width: 350px;
    line-height: 1.3;
  }

  .first-row h5 {
    width: 295px;
    line-height: 1.4!important;
  }

  .btn.reg {
    background-color: green;
    color: #fff;
    padding-left: 40px;
    padding-right: 40px;
    letter-spacing: 1px;
  }

  .second-row {
    padding: 40px 0;
    text-align: center;
  }

  .second-row h5 {
    width: 80%;
    margin: auto;
    line-height: 1.4!important;
  }

  .cards_cont {
    width: 80%;
    margin: auto;
    padding-top: 30px;
  }

  .narrow {
    max-width: 450px;
    margin: auto;
  }

  .counter_head {
    padding-bottom: 45px;
  }

  @media (max-width: 768px) {

    .root_cont {
      padding-top: 20px;
    }

    .image_parallax {
      background-position-y: 20%;
    }

    .first-row {
      margin: 0 auto;
      text-align: center;

      padding: 0;
      flex-direction: column;
    }

    .first-row h1 {
      margin-top: 150px;
      width: 100%;
      font-size: 2.15rem;
      line-height: 1.35;
    }
    .first-row h5 {
      width: 100%;
    }

    .report_cont {
      position: static;
      left: 0;
      top: 0;
      width: 250px;
      max-width: 60%;
      margin: 20px auto 0;
    }

    .second-row h5 {
      width: 100%;
    }

    .cards_cont {
      width: 95%;
    }

    .counter_head {
      padding-bottom: 15px;
    }
  }

</style>
