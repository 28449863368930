<template>
  <LandingPageHome
    :bg_url="'/static/images/LandingPageCover.jpg'"
    bg_pos_y="50%"
  />
</template>

<script>
  import LandingPageHome from "./LandingPageHome"
  export default {
    name: "LandingPageStart",
    components: {LandingPageHome}
  }
</script>

<style scoped>

</style>